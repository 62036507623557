// Purpose: Main React component for the frontend of the application.
// The point od this app is to allow a user to upload an audio file and get a transcription of it.
// The user can then download the transcription as a text file.
// I need to add comments on how to use the website, perhaps in the form of speech bubbles on the left and right
// I also need to add a status window, like a progress bar and error handling and display
// It would be cool to add a play button to play the audio file and highlight the text as it is being played and a way to edit the audio irl
// I also need to add a way to delete the transcriptions and audio files
// There needs to be a selection to choose the language, size of model, and amount of speakers
// It would also be useful to add a field to email the transcription to the user after it is done in case the audio file is very large
import React, { useState, useEffect } from 'react';
import './App.css';

const API_ENDPOINT = 'https://d1978krugcb0ht.cloudfront.net';

function App() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [fileDropped, setFileDropped] = useState(false);
    const [transcribedFiles, setTranscribedFiles] = useState([]);
    const [transcriptData, setTranscriptData] = useState({ text: '', segments: [] });
    const [isLoading, setIsLoading] = useState(false);
     // New state for health check status
    const [healthCheckStatus, setHealthCheckStatus] = useState('');


    
    // Version number
    const [version, setVersion] = useState('0.0.3');

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    // Function to perform the health check
    const performHealthCheck = async () => {
    try {
        // Update the URL to match your setup, use CloudFront URL if through CloudFront
        const url = `${API_ENDPOINT}/health`;
        const response = await fetch(url);
        const data = await response.json();
        console.log("Health Check Result:", data);
        setHealthCheckStatus(`Status: ${data.status}, Version: ${data.version}`);
    } catch (error) {
        console.error('Health Check Failed:', error);
        setHealthCheckStatus('Failed Health Check', error);
    }
    };

    //  Perform the health check automatically on component mount
    useEffect(() => {
        performHealthCheck();
    }, []);

    const handleDragOver = (event) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(false);
        setFileDropped(true);
        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            setSelectedFile(event.dataTransfer.files[0]);
        }
    };

    const downloadTranscript = (fileName, transcript) => {
        const blob = new Blob([transcript], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${fileName}-transcript.txt`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!selectedFile) {
            alert('Please select a file first!');
            return;
        }

        setIsLoading(true); // activating loading state

        const formData = new FormData();
        formData.append('file', selectedFile);

        try {

            // Test for response

            //cloudfront distribution: https://d1978krugcb0ht.cloudfront.net/transcribe
            //load balancer: https://LoadBalancer1-997102201.us-east-1.elb.amazonaws.com/transcribe

            const response = await fetch(`${API_ENDPOINT}/transcribe`, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            const result = await response.json();
            console.log("Transcription Result:", result); // Check the structure of the response
            setTranscriptData(result); // Set the entire transcript object

            const transcriptText = result.transcript.map(segment => 
              segment.speaker ? `${segment.speaker} at ${segment.time}:` : segment.text).join(" ");
             
            // Add the new transcription to the transcribedFiles list
            const newTranscribedFile = {
              name: selectedFile.name,
              content: transcriptText
            };
            setTranscribedFiles([...transcribedFiles, newTranscribedFile]);

            setFileDropped(false); // Reset the file dropped state
            setSelectedFile(null); // Reset the selected file
        } catch (error) {
            console.error('Error uploading file:', error);
        } finally {
          setIsLoading(false);
        }
    };

    return (
        <div className="App">

            <header className="App-header">
              <h1>Audio to Text Converter</h1>{/*Title*/}
            </header>

            {/* Transcribed box */}
            <div 
                className={`upload-box ${isDragging ? 'drag-over' : ''} ${fileDropped ? 'file-dropped' : ''}`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                <form onSubmit={handleSubmit}>
                    <input type="file" onChange={handleFileChange} />
                    <button type="submit">Transcribe</button>
                </form>
                {fileDropped ? (
                    <>
                        <div className="file-icon"></div>
                        <p>{selectedFile ? selectedFile.name : "Drop your file here or click to upload"}</p>
                    </>
                ) : (
                    <p>Drop your file here or click to upload</p>
                )}

                {/* Loading Spinner */}
                {isLoading && <div className = "spinner"></div>}
            </div>  

            {/* Transcribed files box */}
            <div className="transcribed-files-box">
                <h2>Transcribed Files:</h2>
                {transcribedFiles.map((file, index) => (
                    <div key={index}>
                        <p>File: {file.name}</p>
                        <button onClick={() => downloadTranscript(file.name, file.content)}>
                            Download Transcript
                        </button>
                    </div>
                ))}
            </div>

                <div>
                    {/* Button to manually perform health check */}
                    <button onClick={performHealthCheck}>Check Health</button>
                    
                    {/* Display health check status */}
                    {healthCheckStatus && <p>{healthCheckStatus}</p>}

                </div>

                {/* Version */}
            

                <div className="version">Version: {version}</div>     
       </div>
       
    );
}

export default App;